import React, { Component } from 'react'

export default class Contact extends Component {

    render() {
        return (
            <div>
                Это ваша страница с контактами
            </div>
        )
    }

}